.c-btn {
  $color: #fff;
    text-decoration: none;
    appearance: none;
    background: none;
    padding: 0;
    border: none 0;
    margin: 0;
  // 通常ボタン
  &--regular {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 260px;
      height: 54px;
      background: #000;
      padding: 0;
      margin: 0;
      &--en {
        display: inline-block;
        width: auto;
        height: auto;
        padding: 5px 20px 6px;
      }
  }
  // 予約ボタン
  &--reserve {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 260px;
      height: 54px;
      background-color: #ff0000;
      margin: auto;
  }
  // 大きいボタン
  &--big {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      width: auto;
      background: #000;
      padding: 12px 25px;
      margin: 0;
      display: inline-block;
      &--en {
        display: inline-block;
        width: auto;
        height: auto;
        padding: 5px 20px 6px;
      }
  }
}