// ホーム > センター情報
.p-home-about {
  $var: "";
    background-image: linear-gradient(to right, #e80000 0%, #cd0000 100%);
    padding: 34px 0 40px;
    @media #{$pc} {
        padding: 63px 0 66px;
    }
  &__header {
      display: flex;
      align-items: center;
      &--en-US {
        display: block;
        @media #{$pc} {
          display: flex;
          margin: 0 0 20px;
        }
      }
  }
  &__title {
      font-size: 20px;
      font-weight: 700;
      line-height: round(32/20*10)/10;
      margin: 0;
  }
  &__body {
      margin: 18px 0 0;
      @media #{$pc} {
        display: flex;
        justify-content: space-between;
        margin-top: 9px;
      }
  }
  &__price {
      color: #f00;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 4px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      padding: 6px 8px;
      margin: 0 0 5px 20px;
      text-align: center;
      @media #{$pc} {
        margin: 0 0 0 20px;
      }
      &:last-child {
        margin: 0 0 0 20px;
      }
      &--en-US {
        margin: 10px 0 5px 0px;
        text-transform: uppercase;
        &:last-child {
          margin: 0 0 0 0px;
        }
        @media #{$pc} {
          margin: 0 0 5px 20px;
          &:last-child {
            margin: 0 0 0 20px;
          }
        }
      }
      &_wrapper {
        @media #{$pc} {
          display: flex;
          align-items: center;
        }
        &--en-US {
          display: block;
        }
      }
  }
  &__info {
      @media #{$pc} {
        margin-right: 24px;
      }
  }
  // 開館時間
  &__openHour {
      font-size: 14px;
      font-weight: 400;
      line-height: (28/14);
      margin: 0;
      @media #{$pc} {
        font-size: 16px;
        line-height: round(28/16*10)/10;
      }
  }
  // センター住所
  &__address {
      font-size: 12px;
      font-weight: 400;
      line-height: (24/12);
      margin: 16px 0 0;
      @media #{$pc} {
        font-size: 14px;
        line-height: round(28/14*10)/10;
        margin-top: 0;
      }
  }
  // アクセスボタン
  &__btn {
      margin: 24px auto 0;
      @media #{$pc} {
          margin: 0;
      }
    .c-btn {
        margin: 0 auto;
        @media #{$pc} {
          transform: translateY(-7px);
        }
    }
  }
}