
// 予約 > 案内ブロック
.p-booking {
  $padding: 20;
    @media #{$pc} {
      max-width: (980 + $padding*2)*1px;
      padding: 0 $padding+px;
      margin: auto;
    }
  .c-booking-calendar__data {
    &:focus {
        outline: 0;
    }
  }
}


// 予約 > 案内ブロック
.p-booking-info {
  $var: "";
    background: #333;
    padding: 52px 10px 56px;
    margin: 54px 10px 0;
    @media #{$pc} {
        padding: 25px 10px;
        margin: 64px 0 0;
    }
  &__text {
      font-size: 16px;
      font-weight: 700;
      line-height: ceil(28/16*10)/10;
      text-align: justify;
      margin: 0;
      @media #{$pc} {
        text-align: center;
      }
    & + & {
        margin-top: 29px;
        @media #{$pc} {
          margin-top: 28px;
        }
    }
  }
}

// 予約 > ステップブロック コンテナ
.p-booking-steps {
  $var: "";
    margin-top: 70px;
    @media #{$pc} {
      margin-top: 40px;
    }
}

// 予約 > ステップブロック
.p-booking-step {
  $var: "";
    background: #fff;
    margin: 0 10px;
    border-radius: 4px;
    transition: all .4s 0s ease-out;
    @media #{$pc} {
      margin: 0;
    }
  & + & {
      margin-top: 20px
  }
  &.is-hidden {
      height: 0;
      opacity: 0;
      overflow: hidden;
  }
  &__head {
      @media #{$pc} {
        display: flex;
      }
  }
  // ステップ番号
  &__number {
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 40px;
      background: #f00;
      border-radius: 4px 4px 0 0;
      margin: 0;
      @media #{$pc} {
        width: 130px;
        height: 81px;
        border-radius: 4px 0 0 0;
      }
  }
  // 見出し
  &__heading {
      color: #333;
      font-size: 18px;
      font-weight: 700;
      line-height: ceil(20/18*10)/10;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 81px;
      border-bottom: solid 1px #ccc;
      margin: 0;
      @media #{$pc} {
        width: calc(100% - 130px);
      }
  }
  &__headingInner {
      @media #{$pc} {
        transform: translate(-65px, -2px)
      }
  }
  // 入力内容本体
  &__body {
      padding: 20px;
      @media #{$pc} {
        padding: 30px 30px 31px;
      }
    .c-form-select__container {
        margin: 10px auto;
        @media #{$pc} {
          margin: 0 auto;
        }
    }
  }
  &--02 {
    .p-booking-step__heading {
        height: 101px;
        @media #{$pc} {
          height: 81px;
        }
    }
    .p-booking-step__body {
        @media #{$pc} {
          display: flex;
          //align-items: flex-end;
          justify-content: space-between;
        }
    }
  }
}


// 予約 > 日時選択 > カレンダー タブ
.p-booking-tab {
  &__container {
      @media #{$pc} {
        width: 554/(980 - 30*2)*100%;
      }
  }
  &__nav {
      display: flex;
  }
  &__navItem {
      font-size: 16px;
      font-weight: 700;
      appearance: none;
      width: 50%;
      height: 48px;
      background: #eee;
      border: solid 1px #ccc;
      border-bottom: 0;
      padding: 0;
      margin: 0;
    &:focus {
        outline: 0;
    }
    &.is-active {
        background: #fff;
    }
    &:first-of-type {
        border-right: 0;
    }
  }
  //&__body {}
}


// 予約 > 日時選択 > カレンダー
.c-booking-calendar {
  $border: solid 1px #ddd;
    width: 100%;
    border: $border;
    border-collapse: collapse;
  &__header {
      color: #333;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      height: 38px;
      padding: 0;
      border: $border;
      @media #{$pc} {
        height: 40px;;
      }
  }
  &__data {
      color: #333;
      text-align: center;
      vertical-align: top;
      height: 59px;
      padding: 0;
      border: $border;
      position: relative;
      @media #{$pc} {
        height: 71px;;
      }
    &.is-active {
      &::after {
          content: "";
          width: 100%;
          height: 100%;
          border: 2px solid #85c244;
          position: absolute;
          top: 0;
          left: 0;
      }
    }
    &.is-past,
    &.is-notAvailable {
      .c-booking-calendar__dataInner {
          background: #f6f6f6;
      }
    }
    &.is-caution {
      .c-booking-calendar__dataInner {
          background: #fff5f4;
      }
    }
  }
  &__dataInner {
      width: 100%;
      height: 100%;
      position: relative;
  }
  &__date {
      font-family: Lato;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      display: block;
      padding: 11px 0 0;
  }
  &__icon {
    &--available,
    &--notAvailable,
    &--caution {
        display: block;
        width: 12px;
        height: 12px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translate(-50%, 0);
        @media #{$pc} {
          bottom: 20px;
        }
    }
  }
}


// 予約 > 日時選択 > 時間帯選択
.p-booking-time {
  $var: "";
    color: #333;
    background: #f6f6f6;
    padding: 20px 20px 30px;
    @media #{$pc} {
      width: 347/(980 - 30*2)*100%;
      //height: calc(100% - 50px);
      margin-top: 50px;
    }
  &__date {
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      margin: 0;
  }
  &__text {
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      margin: 10px 0 0;
  }
  &__btnContainer {
      margin: 20px 0 0;
  }
  &__btn {
      color: #333333;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      appearance: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      border-radius: 4px;
      border: 1px solid #ccc;
      background: #fff;
      padding: 0 15px;
      @media #{$pc} {
        padding: 0 20px;
      }
    & + & {
        margin-top: 10px;
    }
    &:focus {
      outline: 0;
    }
    &.is-active {
      border: 2px solid #85c244;
    }
    &.is-notAvailable {
        color: #cfcfcf;
        background: #ebebeb;
        border: 1px solid #f6f6f6;
    }
  }
  &__time,
  &__number {
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      @media #{$pc} {
        font-size: 12px;
      }
  }
}


// 予約 > お客様情報
.p-booking-customerInfo {
  $var: "";
    @media #{$pc} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 660px;
      margin: -29px auto 0;
    }
  .c-form-container {
      @media #{$pc} {
        margin-top: 25px;
      }
  }
  .is-half {
      @media #{$pc} {
        width: calc(50% - #{20*1/2*1px});
        //margin: 0;
      }
  }
  .is-full {
      @media #{$pc} {
        width: 100%;
      }
  }
}

// 予約 > お客様情報 > プライバシー
.p-booking-privacy {
  $var: "";
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background: #eee;
    @media #{$pc} {
      width: 100%;
      margin-top: 30px !important;
    }
  &__anchor {
      color: #333;
  }
}


// サブミットボタン コンテナ
.p-booking-submit {
  $var: "";
    padding-bottom: 20px;
    margin: 41px auto 0 !important;
    @media #{$pc} {
      padding-bottom: 30px;
    }
}


// 予約 > メッセージ
.p-booking-message {
  $var: "";
  /* // for debug
  background: url(/_devOnly/p-booking-message--sp.png) center 0 no-repeat;
  & > * { opacity: .65; }
  //*/
    background: #333;
    padding: 23px 10px;
    margin: 20px 10px 0;
    @media #{$pc} {
        padding-bottom: 28px;
        margin: 60px 0 0;
    }
  &__heading {
      font-size: 16px;
      font-weight: 700;
      line-height: ceil(28/16*10)/10;
      text-align: center;
      margin: 0;
  }
  &__text {
      font-size: 14px;
      font-weight: 400;
      line-height: (28/14);
      text-align: center;
      margin-top: 17px;
      @media #{$pc} {
        margin-top: -3px;
      }
  }
  &__address {
      font-size: 14px;
      font-weight: 400;
      line-height: ceil(20/14*10)/10;
      text-align: center;
      margin-top: 19px;
      @media #{$pc} {
        margin-top: 9px;
      }
  }
}