// ヘッダー
.l-header {
  $var: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: (14+32+14)*1px;
    background-image: linear-gradient(to right, #e80000 0%, #cd0000 100%);
    padding: 14px 10px;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    transition: .4s 0s ease-out;
    @media #{$pc} {
      height: (45+50+45)*1px;
      padding: 0 40px; //45px 40px;
      //position: relative;
    }
  &.is-home {
      background: none;
  }
  &.is-scroll {
    background-color: rgba(0, 0, 0, 0.7);
  }
}


// ヘッダー > ロゴ
.c-header-logo {
  $var: #fff;
    width: 239px;
    height: 32px;
    margin: 0;
    z-index: 501;
    @media #{$pc} {
      //width: 373/1200*100%;
      width: 373px;
      min-width: 373px;
      //max-width: 373px;
      height: 50px;
    }
  &__img {
      display: block;
      //width: 100%;
      height: 100%;
  }
}