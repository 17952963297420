.preview {
  &-load {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &-list {
    width: 100%;
    position: relative;
    &-btn {
      position: absolute;
      top: 0;
      height: 100%;
      z-index: 999;
      padding: 10px;
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, .1);
      transition: .6s;
      cursor: pointer;
      &--prev {
        left: 0;
      }
      &--next {
        right: 0;
      }
      &:hover {
        background: rgba(0, 0, 0, .6);
      }
    }
  }
  &-item {
    cursor: pointer;
    transition: opacity 0.4s 0s ease-out;
    &:hover {
      opacity: .7;
    }
    &:nth-child(4n+4) {
      margin-right: 0;
    }
    &-wrapper {
      width: 100%;
      padding: 0 5px;
      vertical-align: bottom;
      img {
        width: 100%;
      }
    }
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    width: 100%;
    height: 100%;
    z-index: 999;
  }
  &-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    height: 100%;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    opacity: 0;
    &-active {
      opacity: 1;
    }
  }
  &-inner {
    img {
      max-width: auto;
      max-width: initial;
    }
  }
  &-close {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    padding: 0;
    cursor: pointer;
    background: #444;
    z-index: 1001;
    &-btn {
      content: "";
      width: 16px;
      height: 16px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
