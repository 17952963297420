// information > 詳細
.p-info-entry {
  $var: "";
    padding: 45px 0 0;
    @media #{$pc} {
      padding-top: 72px;
    }
  // 見出し
  &-heading {
      margin: 0;
    // 日付
    &__date {
        font-size: 18px;
        font-weight: 400;
        line-height: round(28/18*10)/10;
        display: block;
    }
    // タイトル
    &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: round(32/20*10)/10;
        display: block;
        margin-top: 25px;
        @media #{$pc} {
          font-size: 28px;
          line-height: ceil(46/28*10)/10;
          margin-top: 15px;
        }
    }
  }
  // コンテナ
  &__body {
      padding: 43px 0 0;
    & > *:first-child {
        margin-top: 0;
    }
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: round(38/16*10)/10;
        text-align: left;
        margin: 38px 0 0;
    }
    table {
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      border-collapse:separate;
      border-spacing: 0;
      th, td {
        padding: 10px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        p {
          margin: 0;
        }
      }
    }
    .movie {
      width: 100%;
      iframe {
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
      }
    }
  }
  // 一覧に戻るボタン
  &__btns {
      margin: 48px  0 0;
      @media #{$pc} {
        margin-top: 72px;
      }
    .c-btn {
        margin: 0 auto;
    }
  }
}