.c-faq {
  $var: "";
    background: #333;
    margin: 0;
  & + & {
      margin-top: 4px;
  }
  // FAQ ＞ 質問
  &__question {
      font-size: 18px;
      font-weight: 700;
      line-height: (24/18);
      padding: 17px 10px 17px 53px;
      margin: 0;
      position: relative;
      @media #{$pc} {
        padding: 28px 30px 29px 69px;
      }
    &::before {
        content: "Q";
        color: #ff0000;
        font-family: Lato;
        font-size: 28px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        display: block;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        @media #{$pc} {
          left: 26px;
        }
    }
  }
  // FAQ ＞ アンサー
  &__answer {
      //display: none;
      height: 0;
      margin: 0;
      overflow: hidden;
      transition: all .4s 0s ease-out;
  }
  &__answerInner {
      font-size: 16px;
      line-height: (28/16);
      padding: 37px 10px 66px 50px;
      border-top: solid 1px #ccc;
      margin: 0;
      position: relative;
      @media #{$pc} {
        padding: 26px 120px 39px;
      }
      a {
        color: #fff;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
          opacity: .7;
        }
      }
    &::before {
        content: "A";
        color: #888;
        font-family: Lato;
        font-size: 28px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        display: block;
        position: absolute;
        top: 28px;
        left: 15px;
        @media #{$pc} {
          left: 30px;
        }
    }
  }
}