// ピックアップ/おすすめコンテンツ > 一覧
.p-topics-list {
  $var: "";
    padding: 63px 0 0;
    @media #{$pc} {
      padding-top: 56px;
    }
  // ページャー
  &__pager {
      padding-top: 80px;
      @media #{$pc} {
        padding-top: 97px;
      }
  }
}


// ピックアップ/おすすめコンテンツ > 詳細
.p-topics-entry {
  $var: "";
    padding: 52px 0 0;
    @media #{$pc} {
      padding-top: 72px;
    }
  &-heading {
      margin: 0;
    &__date {
        font-size: 18px;
        font-weight: 400;
        line-height: round(28/18*10)/10;
        display: block;
    }
    &__title {
        font-size: 24px;
        font-weight: 700;
        line-height: round(46/24*10)/10;
        display: block;
        margin-top: 18px;
        @media #{$pc} {
          font-size: 28px;
          line-height: round(46/28*10)/10;
        }
    }
  }
  &__body {
      padding: 48px 0 0;
    & > *:first-child {
        margin-top: 0;
    }
    // 小見出し
    h2 {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: normal;
        line-height: 2;
        text-align: left;
        margin: 60px 0 0;
        @media #{$pc} {
          margin-top: 78px;
        }
      & + * {
        margin-top: 6px !important;
        @media #{$pc} {
          margin-top: 43px !important;
        }
      }
    }
    // 段落
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: round(38/16*10)/10;
        text-align: left;
        margin: 38px 0 0;
    }
    // 画像
    img {
      & + p {
          margin-top: 28px;
          @media #{$pc} {
            margin-top: 49px;
          }
      }
    }
  }
}


// ピックアップ/おすすめコンテンツ > 詳細ページ > ページャー
.p-topics-entry-pager {
  $var: "";
    display: flex;
    justify-content: space-between;
    padding: 77px 0 95px;
    position: relative;
    @media #{$pc} {
      justify-content: center;
      padding: 92px 0 0;
    }
  &__prev,
  &__next {
      color: #fff;
      text-decoration: none;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.09px;
      line-height: 1;
      position: relative;
      @media #{$pc} {
        display: flex;
        align-items: center;
      }
    &.is-disabled {
        opacity: .4;
        pointer-events: none;
    }
  }
  &__prev {
      padding: 0 0 0 28px;
      @media #{$pc} {
        padding: 0 61px 0 21px;
      }
    .c-pager__arrow {
        left: 0;
        transform: rotate(180deg);
    }
  }
  &__next {
      padding: 0 28px 0 0;
      @media #{$pc} {
        padding: 0 21px 0 61px;
      }
  }
  &__arrow {
      display: block;
      width: 9px;
      height: 14px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: calc(50% - 7px);
      right: 0;
  }
  &__btns {
      margin: 0;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50% , 0);
      @media #{$pc} {
        position: static;
        transform: none;
      }
      &-en {
        width: calc(100% - 40px);
        text-align: center;
      }
    .c-btn {
        margin: 0;
    }
  }
}