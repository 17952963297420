// アクセス > 
.p-access {
  padding-bottom: 12px;
  margin-top: 56px;
  @media #{$pc} {
    margin-top: 85px;
  }
}
.p-access-address {
$var: "";
  margin: 25px 0 0;
  @media #{$pc} {
    display: flex;
    justify-content: space-between;
    margin-top: 28px
  }
&__text {
    font-size: 18px;
    font-weight: 400;
    line-height: ceil(32/18*10)/10;
    margin: 10px 0 0;
    @media #{$pc} {
      margin-top: 0;
      margin-right: 24px;
    }
}
&__btn {
    margin: 30px 0 0;
    @media #{$pc} {
      margin-top: 8px;
    }
}
&__note {
    font-size: 16px;
    font-weight: 400;
    line-height: ceil(32/16*10)/10;
    margin: 21px 0 0;
    @media #{$pc} {
      margin-top: 32px;
    }
}
}
// アクセス > マップ
.p-access-map {
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 41px 0 0;
    @media #{$pc} {
      margin-top: 50px;
    }
  .gm-style img[src$='.png'] {
    width: 160px !important;
    height: 42px !important;
    transform: translate(50%, 100%);
  }
}


.p-access-wayFromSta {
$var: "";
  margin-top: 65px;
  @media #{$pc} {
    margin-top: 66px;
  }
.c-heading--lv3 {
    text-align: center;
}
&__container {
    margin-top: 36px;
    @media #{$pc} {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 0 60px;
      margin-top: 59px;
    }
}
&__img {
    margin: 0;
    @media #{$pc} {
      width: 460/(1100 - 60*2)*100%;
      margin-top: 0;
    }
}
&__text {
  
  @media #{$pc} {
      margin-top: 0;
      transform: translateY(-4px);
  }
}
}