@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

// foundation
@import "foundation/mixin";           // mixin
@import "foundation/vars";            // 変数系
@import "foundation/normalize";       // normalize.css
@import "foundation/font";            // フォント設定
@import "foundation/base";            // その他の基本設定

// vendor
@import "vendor/modaal";            // モーダルプラグイン
//@import "vendor/slick";             // スライダープラグイン

// utility
@import "utility/text";               // テキスト
@import "utility/devices";            // デバイス
@import "utility/display";            // display プロパティ
@import "utility/margin";             // margin / padding プロパティ
@import "utility/visibility";         // 表示系
@import "utility/container";          // Flexbox

// layout
@import "layout/container";           // コンテナ
@import "layout/header";              // ヘッダー
@import "layout/navGlobal";           // グローバルナビ
@import "layout/navGlobal-megaMenu";  // グローバルナビ > メガメニュー
@import "layout/footer";              // フッター

// components
@import "components/text";        // テキスト / p要素
@import "components/heading";     // 見出し
@import "components/form";        // フォームパーツ
@import "components/btns";        // ボタン
@import "components/link";        // リンク
@import "components/list";        // リスト
@import "components/table";       // テーブル
@import "components/price";       // 料金
@import "components/separator";   // 区切り線
@import "components/modal";       // モーダル調整
@import "components/pageHeader";  // コンテンツ種別 > ページヘッダー
@import "components/topicPath";   // コンテンツ種別 > パンくずリスト
@import "components/navLocal";    // コンテンツ種別 > ローカルナビ
@import "components/info";        // コンテンツ種別 > ご利用案内 > インフォメーション
@import "components/faq";         // コンテンツ種別 > FAQ
@import "components/manners";     // コンテンツ種別 > 観覧マナー
@import "components/topics";      // コンテンツ種別 > トピックス
@import "components/pager";       // コンテンツ種別 > ページャー
@import "components/info-list";   // コンテンツ種別 > Info > 一覧

// project
@import "project/home/heading";   // コンテンツ種別 > トップページ > メインビジュアル
@import "project/home/about";     // コンテンツ種別 > トップページ > センターについて
@import "project/home/info";      // コンテンツ種別 > トップページ > Information
@import "project/home/covid19";   // コンテンツ種別 > トップページ > 新型コロナ対策
@import "project/home/overview";  // コンテンツ種別 > トップページ > 概要
@import "project/home/succession";// コンテンツ種別 > トップページ > 遺産を継承する
@import "project/home/topics";    // コンテンツ種別 > トップページ > ピックアップ/イベント
@import "project/home/news";      // コンテンツ種別 > トップページ > お知らせ
@import "project/info-list";      // コンテンツ種別 > Information > 一覧ページ
@import "project/info-entry";     // コンテンツ種別 > Information > 詳細ページ
@import "project/topics";         // コンテンツ種別 > トピックス
@import "project/guide";          // コンテンツ種別 > ユーザーガイド
@import "project/guide-covid19";  // コンテンツ種別 > ユーザーガイド > 新型コロナ対策
@import "project/access";         // コンテンツ種別 > アクセス
@import "project/booking";        // コンテンツ種別 > 予約
@import "project/faq";            // コンテンツ種別 > よくある質問
@import "project/manners";        // コンテンツ種別 > 観覧マナー
@import "project/about";          // コンテンツ種別 > センターの紹介
@import "project/exhibition";     // コンテンツ種別 > 展示
@import "project/guidebook";      // コンテンツ種別 > ガイドブック
@import "project/publications";   // コンテンツ種別 > 過去の刊行物
@import "project/heritage";       // コンテンツ種別 > 資産情報
@import "project/privacy";        // コンテンツ種別 > プライバシーポリシー
@import "project/sitemap";        // コンテンツ種別 > サイトマップ







// 言語選択モーダル
.p-langSelect {
  $var: "";
    @media #{$pc} {
      max-width: 800px;
      height: 420px;
      //background-color: rgba(0, 0, 0, 0.7);
      margin: 0 auto auto;
      position: relative;
    }
    /*/ for debug
    background: url(/_devOnly/c-langSelect--pc.png) center 0px no-repeat;
    > * { opacity: .65; }
    // */
  // メニューリスト
  &__list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      padding: 16px 2px 0;
      margin: 0;
      @media #{$pc} {
        list-style: none;
        flex-direction: column;
        align-items: center;
        padding: 82px 0 0;
      }
  }
  // メニューアイテム
  &__item {
      width: 50%;
      @media #{$pc} {
        width: auto;
      }
    &:nth-of-type(n+3) {
        margin-top: 27px;
    }
    &:nth-of-type(2n) {
        padding-left: 13px;
        @media #{$pc} {
          padding: 0;
        }
    }
    & + & {
        @media #{$pc} {
          margin-top: 42px;
        }
    }
    // メニュー画像
    img {
      width: auto;
      height: 13px;
      @media #{$pc} {
        height: 18px;
      }
    }
  }
  &__anchor {
    cursor: pointer;
  }
  &__closeBtn {
      display: none;
      @media #{$pc} {
        display: block;
        width: 50px;
        height: 50px;
        background: none;
        padding: 0;
        border: 0;
        margin: 0;
        cursor: pointer;
        position: absolute;
        top: 17px;
        right: 20px;
      }
    &:focus {
        outline: 0;
    }
  }
}


* {
  //outline: dotted 1px red;
}


.react-transform-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.aniversary-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 90%;
  @media #{$pc} {
    width: 50%;
  }
  aspect-ratio: 16 / 9;
  &-mv {
    width: 100%;
    height: 100%;
  }
  &__closeBtn {
      display: block;
      width: 50px;
      height: 50px;
      background: none;
      padding: 0;
      border: 0;
      margin: 0;
      cursor: pointer;
      position: absolute;
      top: -60px;
      right: 0;
      @media #{$pc} {
        right: -60px;
      }
    &:focus {
        outline: 0;
    }
  }
}

.aniversary-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .7);
  z-index: 999;
}