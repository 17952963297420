.coming {
	&-wrapper {
		text-align: center;
		width: 100%;
    max-width: 1100px;
    margin: 0 auto;
	}
	&-text {
		font-size: 32px;
		text-align: center;
	}
}