// トップページ > メインビジュアル
.p-home-heading {
  $var: "";
    width: 100%;
    margin: 0;
    position: relative;
  &__text {
      width: 0;
      height: 0;
      overflow: hidden;
      position: absolute;
  }
}

.p-home-anniversary {
  background-color: #4B4B4B;
  &-inner {
    @media #{$pc} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-flow: row;
    }
    display: flex;
    flex-flow: column;
    padding-bottom: 30px;
  }
  &__text {
    font-size: 30px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: center;
    @media #{$pc} {
      font-size: 40px;
      letter-spacing: 3.5px;
    }
  }
  &__group {
    order: 2;
    text-align: center;
    @media #{$pc} {
      order: 1;
    }
  }
  &__btn {
    margin: 0 auto;
  }
  &__img {
    max-width: 380px;
    width: 100%;
    order: 1;
    margin: 0 auto;
    @media #{$pc} {
      margin: 0;
      max-width: 360px;
      order: 2;
    }
  }
}