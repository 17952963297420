.notfound {
	text-align: center;
	padding-top: 60px;
	&-text {
		margin: 0;
		padding: 0;
		text-align: center;
	}
	&-btn {
		text-align: center;
		display: flex;
		justify-content: center;
	}
}