// 産業遺産を継承する
.p-home-succession {
  $var: "";
    width: 100%;
    height: 0;
    padding: 600px 0 0;
    overflow: hidden;
    position: relative;
    @media #{$pc} {
        padding-top: 520px;
    }
  &__bg {
    &--sp,
    &--pc {
      width: 100%;
      height: 100%;;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &_wrapper {
    width: 300/375*100%;
    position: absolute;
    top: 24.66667%;
    left: 50%;
    transform: translate(-50%, 0);
    @media #{$pc} {
      width: 335/375*100%;
      top: 24.30769%;
      transform: translate(-50%, 0);
    }
    &--en-US {
      top: 8%;
      @media #{$pc} {
        top: 24.30769%;
      }
    }
  }
  // 見出し
  &__heading {
      width: 100%;
      min-width: initial;
      margin: 0 auto 54px;
      @media #{$pc} {
        width: 40%;
        min-width: 500px;
        margin: 0 auto 14px;
      }
    &__text {
      width: 100%;
      text-align: center;
      font-size: 14px;
      margin: 0 auto 34px;
      font-weight: normal;
    }
    img {
        width: 100%;
        max-width: none;
    }
  }
  // テキスト
  &__text {
      font-size: 18px;
      font-weight: 400;
      line-height: round(36/18*10)/10;
      text-align: center;
      margin: 0;
  }
}